<template>
  <div class="m-con" v-loading="loading">
	<div class="m-main u-container">
		<div class="m-main-title">
			<span>联系我们</span>
		</div>
		<div class="m-main-text" v-html="content"></div>
	</div>
  </div>
</template>

<script>
	import {getSinglePage} from "@/api/common.js"
	export default {
		data(){
			return {
				content:'',
				loading:false,
			}
		},
		mounted() {
			this.getInfo()
		},
		methods:{
			async getInfo(){
				this.loading = true
				let res = await getSinglePage({id:1})
				setTimeout(()=>{
					this.loading = false
				},500)
				if(res.code == 200){
					this.content = res.data.detail ||''
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.m-con{
		width: 100%;
		min-height: 600px;
		background: #F1F1F1;
    background: linear-gradient(to bottom,#11a6ff,#2bb0ff,#5bc2ff,#91d6ff,#cbecff,#fdffff);
		padding-bottom: 100px;
		padding-top: 60px;
		.m-main{
			background-color: #fff;
			.m-main-title{
				height: 64px;
				border-bottom: 1px solid #EEEEEE;
				font-size: 20px;
				line-height: 64px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #000000;
				padding-left: 38px;
			}
			.m-main-text{
				padding: 40px;
				font-size: 16px;
				color: #666666;
			}
		}
	}
	.m-head-bg{
		width: 100%;
		min-width: 1200px;
		height: 430px;
		background: url(../assets/img/common/m-head-bg.png) no-repeat;
		background-size: cover;
		background-position: center 0;
	}
</style>
